export const en = {
  ui: {
    buttonLogin: 'Login',
    buttonSignUp: 'Sign up',
    buttonBackLogin: 'Back to login',
    buttonGoToLogin: 'Go to login',
    loginButtonTitle: 'Login with',
    rememberText: 'I just remembered!',
    resetTitle: 'Reset Password',
    registrationButtonTitle: 'Sign up with ',
    forgotPasswordText1: 'Forgot your ',
    forgotPasswordText2: 'password?',
    contactInformation: 'Contact information',
    customerMessagePlaceholder: 'Write a message to {{profigleName}}',
    resetHint:
      "Please enter your email and we'll send you a link to get back into your account",
    createAccountMessage1: 'By creating an account, you agree to our ',
    createAccountMessage2: 'Terms of Service ',
    createAccountMessage3: 'and ',
    createAccountMessage4: 'Privacy & Cookie Policy.',
    titleForgot: 'Forgot Password',
    email: 'Email',
    alreadyHaveAccount1: 'Already have an account? ',
    allServices: 'All services',
    dontHaveAccount1: "Don't have an account? ",
    noServicesFound: 'No services found.',
    noAppointmentTimesFoundExtend: 'No available times were found, search further ahead?',
    noAppointmentTimesFoundFinal: 'No available times were found',
    noAppointmentTimesFoundExtendButton: 'Search further',
    password: 'Password',
    firstName: 'First name',
    surname: 'Surname',
    dueDate: 'Due date',
    subtotal: 'Subtotal',
    invoiceFee: 'Invoice Fee',
    adminFee: 'Administrative fee',
    subscriptionReduction: 'Period reduction',
    invoiceBillingTitle: 'Billing information',
    invoiceTypeTitle: 'Individual / Company',
    invoiceTypeIndividual: 'Individual',
    invoiceTypeCompany: 'Company',
    invoiceCountryTitle: 'Country',
    invoiceCompanyInfoTitle: 'Company Information',
    invoiceCompanyFieldTitle: 'Company',
    invoiceCompanyNumberFieldTitle: 'Company number',
    invoiceCompanyReferenceFieldTitle: 'Invoice reference',
    invoicePersonalIdentityNumberTitle: 'Identity number',
    invoicePersonalIdentityNumberFieldTitle: 'Your identity number',
    invoiceBillingAddressTitle: 'Billing Address',
    invoiceStreetFieldTitle: 'Street',
    invoicePostalFieldTitle: 'Postal',
    invoiceCityFieldTitle: 'City',
    changePassword: 'Change password',
    accountTitle: 'My Account',
    accountName: 'Name',
    confirmPassword: 'Confirm password',
    forgotPasswordSuccess:
      'An email has been sent with further instructions on how to recover your password',
    resetPasswordSuccess: 'Your password has been updated!',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    send: 'Send',
    update: 'Update',
    successUpdate: 'Changes saved successfully',
    registrationTitle: 'Sign up to make a booking',
    loginTitle: 'Login to make a reservation',
    titleForgotPage: 'Did you lose your password?',
    bookings: 'Bookings',
    unpaid: 'Unpaid',
    payNow: 'Pay now',
    addons: 'Addons',
    add: 'Add',
    account: 'Account',
    logout: 'Logout',
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
    today: 'Today',
    quantity: 'Quantity',
    sum: 'Sum',
    or: 'or',
    book: 'Book',
    buy: 'Buy',
    calendarSelectedPeriod: 'Chosen period:',
    calendarSelectStartDate: 'Choose start date',
    calendarSelectEndDate: 'Choose end date',
    spotsLeft: '{{count}} spots left',
    spotsLeftSingle: '1 spot left',
    spotsLeftFull: 'Fully booked',
    checkout: 'Checkout',
    bookingsCardAddButton: '+ Add more bookings',
    checkoutPromotionCodeCardAddButton: '+ Add promotion code',
    checkoutPromotionCodeCardRemoveButton: '- Remove promotion code',
    moreServices: 'More services',
    readMore: 'Read more',
    readLess: 'Read less',
    courseDuration: 'Duration',
    start: 'Start',
    end: 'End',
    timeZoneDifferText: 'Times are shown in company local time',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    manualAdjustment: 'Manual adjustment',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    save: 'Save',
    untilCancellation: 'Until cancellation',
    addToCart: 'Add to cart',
    min: 'Min: {{value}} min',
    max: 'Max: {{value}} min',
    minute: 'Minute',
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    quarter: 'Quarter',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    from: 'From',
    checkoutFooterGoToCheckout: 'Checkout',
    checkoutRemoveBooking: 'Remove booking from checkout',
    checkoutRemoveBookingMessage: 'Are you sure you want to remove this booking?',
    checkoutRemoveBookingYes: 'Yes, remove it',
    promotionCode: 'Promotion code',
    promotionCodeOrderText: 'Promotion code {{name}}',
    promotionCodeError: 'Code is invalid',
    discount: 'discount',
    discounts: 'Discounts',
    vouchers: 'Vouchers',
    memberships: 'Memberships',
    spent: 'spent',
    balance: 'Balance',
    transactionHistory: 'Transaction history',
    noTransactionsFound: 'No transactions found',
    total: 'Total',
    payment: 'Payment',
    subscriptionNextPayment: 'Next payment',
    subscriptionNextPaymentRetry: 'Next payment attempt',
    subscriptionLastPayment: 'Last Payment',
    cancelSubscription: 'Cancel subscription',
    cancelSubscriptionYes: 'Yes, cancel',
    cancelSubscriptionNo: 'No, dont cancel',
    subscriptionChangePaymentMethod: 'Change card',
    subscriptionAddCardTitle: 'Change card for subscription',
    subscriptionAddCardConfirmTitle: 'Confirm change',
    subscriptionAddCardConfirmDescription:
      'If the subscription is past due the new card will be retried shortly.',
    subscriptionAddCardErrorDefault:
      'Failed to add card, please check your card credentials and try again.',
    subscriptionPaymentMethodExpires: 'Expires',
    subscriptionTransactionHistory: 'Payment History',
    subscriptionPastDueInfoText:
      'We have not been able to charge the subscription card. If needed, please change card before next payment attempt.',
    started: 'started',
    ends: 'ends',
    refunded: 'Refunded',
    paid: 'paid',
    due: 'due',
    payOn: 'Pay on',
    arrival: 'Arrival',
    payWith: 'Pay with',
    creditCard: 'Credit Card',
    swish: 'Swish',
    invoice: 'Invoice',
    cancelBookingMessage: 'Are you sure you want to cancel',
    leaveWaitingMessage: 'Are you sure you want to leave this waiting list',
    cancelSubscriptionMessageWithNotice1:
      'Your subscription has a {{noticePeriodDays}} days notice period. If you cancel now the ',
    cancelSubscriptionMessageWithNotice2:
      'last payment will be made {{lastAtWithNoticePeriod}}',
    cancelSubscriptionMessage: 'Are you sure you want to cancel your subscription?',
    bookingCancelButton: 'Yes, cancel it',
    noKeepIt: 'No, keep it',
    yes: 'Yes',
    no: 'No',
    includingTax: 'Including tax',
    cardNumber: 'Credit card number',
    availableDiscounts: 'You have available',
    validTo: 'Valid to',
    validFor: 'Valid for',
    cvv: 'CVV',
    confirmationTitle: 'Thank you{{firstName}}!',
    confirmText1: 'Your booking is registered.',
    confirmText2: ' You can view your bookings and make changes at your account under ',
    confirmText3: 'bookings.',
    showBookings: 'Show my bookings',
    receipt: 'Receipt',
    ok: 'OK',
    cardPayment: 'Credit Card Information',
    cardHolder: 'Card Holder',
    cardPaymentErrorDefault:
      'Payment failed, please check your card credentials and try again.',
    swishMCommerceTitle: 'Pay on this mobile',
    swishECommerceTitle: 'Ange ditt mobilnummer',
    swishPayButton: 'Betala med Swish',
    swishStatusWaitingTitle: 'Waiting for payment to finish in the Swish app',
    swishErrorRequestInProgress: 'You already have a payment in progress',
    tax: 'including tax {{value}}',
    checkoutAcceptTerms1: 'I accept the ',
    checkoutAcceptTerms2: 'terms',
    termsModalTitle: 'Terms',
    errorTerms: 'You need to accept the terms',
    address: 'Address',
    homePage: 'Home page',
    tomorrow: 'Tomorrow',
    noBookingMessage: 'You have no active bookings',
    noWaitingListsMessage: 'You are not on any waiting lists',
    noVoucherMessage: 'You have no active vouchers',
    noMembershipMessage: 'You have no active memberships',
    noPaymentsMessage: 'You have no payments',
    noSubscriptionsMessage: 'You have no subscriptions',
    subscriptionGroupHeaderActive: 'Active',
    subscriptionGroupHeaderPastDue: 'Late payment',
    subscriptionGroupHeaderUnderNotice: 'Under notice',
    subscriptionGroupHeaderCancelled: 'Cancelled',
    subscriptionStatusActive: 'Active',
    subscriptionStatusPastDue: 'Late payment',
    subscriptionStatusUnderNotice: 'Under notice',
    subscriptionStatusCancelled: 'Cancelled',
    refresh: 'Refresh',
    bookingStart: 'Booking Start',
    bookingEnd: 'Booking End',
    groupLeaders: 'Group Leaders',
    schedule: 'Schedule',
    select: 'Select',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    haveBookingThis: 'You have booked this!',
    courseFull: 'Fully booked!',
    waitingList: 'Waiting list',
    waitingLists: 'Waiting lists',
    payments: 'Payments',
    subscriptions: 'Subscriptions',
    mySubscriptions: 'My Subscriptions',
    myPayments: 'My Payments',
    loadMore: 'Load more',
    occasion: 'occasion',
    occasions: 'occasions',
    waitingListText: 'You are on the waiting list!',
    leaveWaitingList: 'Leave waiting list',
    confirmYourOrder: 'Confirm your order',
    subscriptionInfoTitle: 'Subscription payment',
    subscriptionInfoCardDescription1:
      'This payment creates a subscription and next payment is',
    subscriptionInfoCardDescription2: ' {{time}}.',
    subscriptionInfoCardDescription3FirstPayment:
      " This first payment has a reduced price since it isn't for a full period, future payments will be fully priced.",
    subscriptionConfirmCardDescription1: 'Your credit card will be charged ',
    subscriptionConfirmCardDescription2: ' {{time}} ',
    subscriptionConfirmCardDescription3: 'until cancellation.',
    subscriptionInfoInvoiceDescription1:
      'This payment creates a subscription and next invoice is sent',
    subscriptionInfoInvoiceDescription2: ' {{time}}.',
    subscriptionInfoInvoiceDescription3FirstPayment:
      " This first invoice has a reduced price since it isn't for a full period, future invoices will be fully priced.",
    subscriptionConfirmInvoiceDescription1: 'Invoice is sent',
    subscriptionConfirmInvoiceDescription2: ' {{time}} ',
    subscriptionConfirmInvoiceDescription3: 'until cancellation.',
    myBookings: 'My Bookings',
    myVouchers: 'My Vouchers',
    myWaitingLists: 'My Waiting Lists',
    myMemberships: 'My Memberships',
    onWaitingList: 'Joined',
    cancelBooking: 'Cancel Booking',
    orderDetailsTitle: 'Order details',
    yearly: 'yearly',
    quarterly: 'quarterly',
    monthly: 'monthly',
    weekly: 'weekly',
    daily: 'daily',
    showMore: 'Show more',
    confirm: 'Confirm',
    checkoutBankIDCardTitle: 'BankID',
    checkoutBankIDStartAuth: 'Identify with BankID',
    checkoutBankIDIdentify: 'Identify with BankID',
    checkoutBankIDOpenOnThisDevice: 'Open BankID on this device instead',
    checkoutBankIDOpenOnDifferentDevice: 'Open BankID on another device instead',
    checkoutBankIDOpenBankID: 'Open BankID',
    checkoutBankIDTryAgain: 'Try again',
    checkoutBankIDCancel: 'Cancel',
    checkoutBankIDCompleted: 'Verified with BankID!',
    checkoutBankIDNotCompleted: 'BankID is not completed',
    checkoutBankIDHintCodeOutstandingTransactionDesktop:
      'Start the BankID app and scan the QR code.',
    checkoutBankIDHintCodeOutstandingTransactionMobile:
      'Please click the button below to start your BankID app.',
    checkoutBankIDHintCodeUserSign:
      'Enter your security code in the BankID app and select Identify or Sign.',
    checkoutBankIDHintCodeUserCancel: 'Action cancelled.',
    checkoutBankIDHintCodeStartFailed:
      "The scan failed. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.",
    checkoutSignDocumentTitle: 'Sign agreement',
    checkoutSignDocumentNotSentPart1Text: 'To complete this booking you need to sign ',
    checkoutSignDocumentNotSentPart2Text: 'an agreement. ',
    checkoutSignDocumentNotSentPart3Text:
      'Press the button below to have it sent to you via ',
    checkoutSignDocumentNotSentPart4Text: 'email and/or sms.',
    checkoutSignDocumentNotSentPart3TextApiDelivery:
      'Press the button below to open the agreement and ',
    checkoutSignDocumentNotSentPart4TextApiDelivery: 'sign.',
    checkoutSignDocumentPendingPart1Text: 'Waiting for the agreement to be signed...',
    checkoutSignDocumentPendingPart2Text:
      "Press the green button below when you've signed the agreement.",
    checkoutSignDocumentReturnToFinishText:
      "Return here after you've signed the agreement and conntinue with the steps below to finish the booking.",
    checkoutSignDocumentSignedText: 'The agreement has been signed!',
    checkoutSignDocumentSendButton: 'Send agreement',
    checkoutSignDocumentOpenButton: 'Open agreement',
    checkoutSignDocumentPendingButton: "I've signed!",
    checkoutSignDocumentNotSignedError:
      'Sorry, but the agreement has not been signed yet. Please try again',
    checkoutAddonsMandatoryError: 'Choose at least one addon',
    checkoutItemUserProxyTitle: 'Booking for a relative?',
    checkoutItemUserProxyDescription: 'Add the person you would like to book for',
    checkoutItemUserProxyMaxRecipientsDescription:
      'Add the person you would like to book for, max {{maxRecipients}} can be booked',
    checkoutItemAddUserProxyButton: 'Add person',
    checkoutItemAddUserProxyToBookingButton: 'Choose',
    checkoutItemDeleteUserProxyTitle: 'Remove from booking',
    checkoutItemDeleteUserProxySubtitle:
      'Do you want to remove this person from the booking?',
    checkoutItemDeleteUserProxyYes: 'Yes, remove',
    checkoutItemUserProxyMandatoryError: 'Choose at least one relative',
    checkoutItemUserProxyAlreadyBookedError: 'Person is already booked',
    checkoutItemUserProxyBookingFullError:
      'Person cannot be added due to booking being fully booked',
    checkoutItemUserProxyMaxRecipientsError: 'Max number of persons have been added',
    addUserProxyModalTitle: 'Add new person',
    addUserProxyModalSubtitle: 'Enter information of the person this booking is for',
    addUserProxyModalSectionName: 'Name',
    addUserProxyModalSectionBirthday: 'Birthday',
    addUserProxyModalSectionEmail: 'Email',
    addUserProxyErrorEmailExists: 'Email already exists',
    birthday: 'Birthday',
    deleteAccountButton: 'Delete account',
    deleteAccountModalTitle: 'Delete your account',
    deleteAccountModalText:
      'Are you sure you want to delete your account? It cannot be recovered afterwards.',
    deleteAccountModalButtonConfirm: 'Yes, delete',
    deleteAccountModalButtonCancel: 'Cancel',
    errorAccountDeleteActiveBookings:
      'This account has active bookings and can therefore not be deleted. Please manage your bookings first and try again',
    errorEmailLogin: 'Email or password are not correct',
    errorEmailRegistration: 'An account with this email already exist',
    errorRequired: 'Field is required',
    errorInvalidDate: 'Invalid date',
    errorInvalidEmail: 'Email is not valid',
    errorPasswordShort: 'Password is too short',
    errorConfirm: 'Password and confirm should be equal',
    errorCountry: 'Country cannot be empty',
    errorFirstName: 'First name cannot be empty',
    errorLastName: 'Last name cannot be empty',
    errorPhoneNumber: 'Phone number cannot be empty',
    errorEmailField: 'Email cannot be empty',
    errorPostal: 'Postal cannot be empty',
    errorStreet: 'Street cannot be empty',
    errorCity: 'City cannot be empty',
    errorCompany: 'Company cannot be empty',
    errorCompanyNumber: 'Company number cannot be empty',
    errorCompanyNumberField: 'Invalid format',
    errorIdentityNumber: 'Identify number reference cannot be empty',
    errorMessage: 'Message cannot be empty',
    errorCheckoutConfirmed: 'This checkout has already been completed',
    errorCheckoutNotFound: 'Checkout could not be found',
    errorHashNotFound: 'Payment link could not be found',
    errorLinkExpired: 'Link has expired',
    errorCheckoutDeleted: 'Booking has been deleted',
    errorMessageDefault: 'Something went wrong, please try again',
    waitingListModalTitle: 'Add yourself or someone else to the waiting list?',
    waitingListModalAddYourself: 'Myself',
    waitingListModalAddSomeoneElse: 'Someone else',
    waitingListModalAddNames: 'Add persons to the waiting list',
    waitingListModalChooseNames: 'Choose persons or add a new one',
    waitingListModalAddPerson: 'Add new person',
    makeItAGift: 'Make it a gift',
    recipientEmail: 'Recipient email',
    giftFirstName: 'Recipient first name',
    giftLastName: 'Recipient last name',
    errorGiftEmail: 'Email is required',
    errorGiftFirstName: 'First name is required',
    errorGiftLastName: 'Last name is required',
    giftRecipientAdd: '+ Add recipient',
    giftRecipientRemove: '- Remove recipient',
    giftRequired: 'Add recipient to make it a gift',
    scheduleDescription: 'Contains all available occasions for this week',
    hide: 'Hide',
    show: 'Show',
    noOccasions: 'No occassions are available at the moment',
  },
};
