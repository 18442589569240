import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
`;

export const CardPriceData = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-right: 40px;
  @media ${devices.mobile} {
    font-size: 15px;
    margin-right: 25px;
  }
`;

export const CardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin-top: 3px;
`;

export const ServiceDuration = styled.div`
  margin-left: 5px;
  line-height: 20px;
`;

export const SlotsContainer = styled.div`
  margin-top: 24px;
  padding: 0 25px;
`;

export const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
`;

export const SlotsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  > div {
    min-width: 135px;
  }
`;

export const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 30px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;
