import { toJS } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Booking, BookingStore, Entries } from 'store/BookingStore';
import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';

interface IProps {
  bookingsPerPage: number;
}

export const useBookings = ({ bookingsPerPage }: IProps) => {
  const [activeBooking, setActiveBooking] = useState<Booking | undefined>();
  const [activeEntry, setActiveEntry] = useState<Entries | undefined>();

  const { setBackButton } = HeaderStore;
  const { getBookings, bookings, deleteBooking, loading, pagination } = BookingStore;
  const { isUserLoggedIn } = LoginStore;

  const history = useHistory();

  useEffect(() => {
    setBackButton(true);
    if (isUserLoggedIn()) {
      getBookings(1, bookingsPerPage);
    } else {
      localStorage.prevPath = history.location.pathname;
      history.replace('/auth');
    }
  }, [setBackButton, isUserLoggedIn, getBookings]);

  return useObserver(() => ({
    activeBooking: { activeBooking, setActiveBooking },
    activeEntry: { activeEntry, setActiveEntry },
    pagination: toJS(pagination),
    bookings: toJS(bookings),
    getBookings: (page: number = 1) => getBookings(page, bookingsPerPage),
    deleteBooking,
    loading,
  }));
};
