export const sv = {
  ui: {
    buttonLogin: 'Logga in',
    buttonSignUp: 'Registrera',
    buttonBackLogin: 'Tillbaka till logga in',
    buttonGoToLogin: 'Gå till logga in',
    loginButtonTitle: 'Logga in med',
    rememberText: 'Jag kom just på!',
    resetTitle: 'Återställ lösenord',
    registrationButtonTitle: 'Registrera med ',
    forgotPasswordText1: 'Glömt ditt ',
    forgotPasswordText2: 'lösenord?',
    contactInformation: 'Kontaktuppgifter',
    customerMessagePlaceholder: 'Skriv ett meddelande till {{profileName}}',
    resetHint:
      'Var vänlig ange din email så skickar vi instruktioner för att återställa ditt lösenord',
    createAccountMessage1: 'Genom att skapa ett konto godkänner du våra ',
    createAccountMessage2: 'Användarvillkor ',
    createAccountMessage3: 'och ',
    createAccountMessage4: 'Policy.',
    titleForgot: 'Glömt lösenord',
    email: 'Email',
    alreadyHaveAccount1: 'Har du redan ett konto? ',
    allServices: 'Alla tjänster',
    dontHaveAccount1: 'Har du inte ett konto? ',
    noServicesFound: 'Vi hittade tyvärr inga tjänster',
    noAppointmentTimesFoundExtend:
      'Inga lediga tider hittades, vill du söka längre framåt?',
    noAppointmentTimesFoundFinal: 'Inga lediga tider hittades',
    noAppointmentTimesFoundExtendButton: 'Sök längre fram',
    password: 'Lösenord',
    firstName: 'Förnamn',
    surname: 'Efternamn',
    dueDate: 'Förfallodatum',
    subtotal: 'Delsumma',
    invoiceFee: 'Fakturaavgift',
    adminFee: 'Administrativ avgift',
    subscriptionReduction: 'Periodreducering',
    invoiceBillingTitle: 'Betalningsuppgifter',
    invoiceTypeTitle: 'Privat / Företag',
    invoiceTypeIndividual: 'Privat',
    invoiceTypeCompany: 'Företag',
    invoiceCountryTitle: 'Land',
    invoiceCompanyInfoTitle: 'Företagsuppgifter',
    invoiceCompanyFieldTitle: 'Företag',
    invoiceCompanyNumberFieldTitle: 'Organisationsnummer',
    invoiceCompanyReferenceFieldTitle: 'Fakturareferens',
    invoicePersonalIdentityNumberTitle: 'Personnummer',
    invoicePersonalIdentityNumberFieldTitle: 'Ditt personnummer',
    invoiceBillingAddressTitle: 'Faktureringsadress',
    invoiceStreetFieldTitle: 'Adress',
    invoicePostalFieldTitle: 'Postkod',
    invoiceCityFieldTitle: 'Stad',
    changePassword: 'Uppdatera lösenord',
    accountTitle: 'Mitt konto',
    accountName: 'Namn',
    confirmPassword: 'Bekräfta lösenord',
    forgotPasswordSuccess:
      'Ett email har skickats med instruktioner om hur du återställer ditt lösenord',
    resetPasswordSuccess: 'Ditt lösenord har uppdaterats!',
    monday: 'Måndag',
    tuesday: 'Tisdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lördag',
    sunday: 'Söndag',
    send: 'Skicka',
    update: 'Uppdatera',
    successUpdate: 'Ändringarna sparades',
    registrationTitle: 'Registrera dig för att boka',
    loginTitle: 'Logga in för att boka',
    titleForgotPage: 'Har du glömt ditt lösenord?',
    bookings: 'Bokningar',
    unpaid: 'Obetalt',
    payNow: 'Betala nu',
    addons: 'Tillägg',
    add: 'Lägg till',
    account: 'Konto',
    logout: 'Logga ut',
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
    today: 'Idag',
    quantity: 'Antal',
    sum: 'Summa',
    or: 'eller',
    book: 'Boka',
    buy: 'Köp',
    calendarSelectedPeriod: 'Vald period:',
    calendarSelectStartDate: 'Välj startdatum',
    calendarSelectEndDate: 'Välj slutdatum',
    spotsLeft: '{{count}} platser kvar',
    spotsLeftSingle: '1 plats kvar',
    spotsLeftFull: 'Fullbokad',
    checkout: 'Kassa',
    bookingsCardAddButton: '+ Lägg till fler bokningar',
    checkoutPromotionCodeCardAddButton: '+ Lägg till rabattkod',
    checkoutPromotionCodeCardRemoveButton: '- Ta bort rabattkod',
    moreServices: 'Fler tjänster',
    readMore: 'Läs mer',
    readLess: 'Läs mindre',
    courseDuration: 'Period',
    start: 'Start',
    end: 'Slut',
    timeZoneDifferText: 'Tiderna visas i företagets lokala tid',
    cancel: 'Avbryt',
    cancelled: 'Avbokade',
    manualAdjustment: 'Manuell justering',
    phone: 'Telefon',
    phoneNumber: 'Telefonnummer',
    save: 'Spara',
    untilCancellation: 'Tillsvidare',
    addToCart: 'Lägg till bokning',
    min: 'Min: {{value}} min',
    max: 'Max: {{value}} min',
    minute: 'Minut',
    hour: 'Timme',
    day: 'Dygn',
    week: 'Vecka',
    month: 'Månad',
    year: 'År',
    quarter: 'Kvartal',
    january: 'Januari',
    february: 'Februari',
    march: 'Mars',
    april: 'April',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    august: 'Augusti',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    from: 'Från',
    checkoutFooterGoToCheckout: 'Kassa',
    checkoutRemoveBooking: 'Ta bort bokning från kassa',
    checkoutRemoveBookingMessage: 'Är du säker på att du vill ta bort bokningen?',
    checkoutRemoveBookingYes: 'Ja, ta bort',
    promotionCode: 'Rabattkod',
    promotionCodeOrderText: 'Rabattkod {{name}}',
    promotionCodeError: 'Koden är inte giltig',
    discount: 'rabatt',
    discounts: 'Rabatter',
    vouchers: 'Värdekort',
    memberships: 'Medlemskap',
    spent: 'spenderat',
    balance: 'Saldo',
    transactionHistory: 'Betalningshistorik',
    noTransactionsFound: 'Inga transaktioner hittades',
    total: 'Totalt',
    payment: 'Betalning',
    subscriptionNextPayment: 'Nästa betalning',
    subscriptionNextPaymentRetry: 'Nästa betalningsförsök',
    subscriptionLastPayment: 'Sista betalning',
    cancelSubscription: 'Avsluta abonnemang',
    cancelSubscriptionYes: 'Ja, avsluta',
    cancelSubscriptionNo: 'Nej, avsluta inte',
    subscriptionChangePaymentMethod: 'Byt kort',
    subscriptionAddCardTitle: 'Byt abonnemangskort',
    subscriptionAddCardConfirmTitle: 'Bekräfta bytet',
    subscriptionAddCardConfirmDescription:
      'Om abonnemanget har en utestående betalning så görs ett nytt försök inom kort.',
    subscriptionAddCardErrorDefault:
      'Misslyckades att lägga till kortet, var vänlig se över kortuppgifterna och försök igen.',
    subscriptionPaymentMethodExpires: 'Utgår',
    subscriptionTransactionHistory: 'Betalningshistorik',
    subscriptionPastDueInfoText:
      'Vi har inte lyckats ta betalt med kortet på abonnemanget. Vid behov, var vänlig byt kort inför nästa betalning.',
    started: 'Startad',
    ends: 'Slutar',
    subscriptions: 'Abonnemang',
    mySubscriptions: 'Mina abonnemang',
    refunded: 'Återbetald',
    paid: 'betald',
    due: 'förfallen',
    payOn: 'Betala vid',
    arrival: 'Ankomst',
    payWith: 'Betala med',
    creditCard: 'Kort',
    swish: 'Swish',
    invoice: 'Faktura',
    cancelBookingMessage: 'Är du säker på att du vill avboka',
    leaveWaitingMessage: ' Är du säker på att du vill lämna väntelistan',
    cancelSubscriptionMessageWithNotice1:
      'Abonnemanget har en {{noticePeriodDays}} dagars uppsägningsperiod. Om du avslutar nu kommer ',
    cancelSubscriptionMessageWithNotice2:
      'sista betalningen göras {{lastAtWithNoticePeriod}}',
    cancelSubscriptionMessage: 'Är du säker på att du vill avsluta abonnemanget?',
    bookingCancelButton: 'Ja, avboka',
    noKeepIt: 'Nej, behåll',
    yes: 'Ja',
    no: 'Nej',
    includingTax: 'Inklusive moms',
    cardNumber: 'Kortnummer',
    availableDiscounts: 'Du har tillgängliga',
    validTo: 'Giltig till',
    validFor: 'Gäller för',
    cvv: 'CVV',
    confirmationTitle: 'Tack{{firstName}}!',
    confirmText1: 'Din bokning är registrerad.',
    confirmText2: ' Du kan se dina bokningar och göra ändringar på ditt konto under ',
    confirmText3: 'bokningar.',
    showBookings: 'Visa mina bokningar',
    receipt: 'Kvitto',
    ok: 'OK',
    cardPayment: 'Kortuppgifter',
    cardHolder: 'Kortinnehavare',
    cardPaymentErrorDefault:
      'Betalningen misslyckades, var vänlig se över kortuppgifterna och försök igen.',
    swishMCommerceTitle: 'Betala på denna mobil',
    swishECommerceTitle: 'Ange ditt mobilnummer',
    swishPayButton: 'Betala med Swish',
    swishStatusWaitingTitle: 'Väntar på att betalningen slutförs i Swish-appen',
    swishErrorRequestInProgress: 'Du har redan en pågående swishbetalning',
    tax: 'inklusive moms {{value}}',
    checkoutAcceptTerms1: 'Jag accepterar ',
    checkoutAcceptTerms2: 'villkoren',
    termsModalTitle: 'Villkor',
    errorTerms: 'Du behöver acceptera villkoren',
    address: 'Adress',
    homePage: 'Hemsida',
    tomorrow: 'Imorgon',
    noBookingMessage: 'Du har inga aktiva bokningar',
    noWaitingListsMessage: 'Du är inte uppskriven på någon väntelista',
    noVoucherMessage: 'Du har inga aktiva värdekort',
    noMembershipMessage: 'Du har inga aktiva medlemskap',
    noPaymentsMessage: 'Du har inga betalningar',
    noSubscriptionsMessage: 'Du har inga abonnemang',
    subscriptionGroupHeaderActive: 'Aktiva',
    subscriptionGroupHeaderPastDue: 'Sent betalda',
    subscriptionGroupHeaderUnderNotice: 'Uppsagda',
    subscriptionGroupHeaderCancelled: 'Avslutade',
    subscriptionStatusActive: 'Aktiv',
    subscriptionStatusPastDue: 'Sen betalning',
    subscriptionStatusUnderNotice: 'Uppsagd',
    subscriptionStatusCancelled: 'Avslutad',
    refresh: 'Uppdatera',
    bookingStart: 'Bokning öppnar',
    bookingEnd: 'Bokning stänger',
    groupLeaders: 'Ansvariga',
    schedule: 'Schema',
    select: 'Välj',
    selectAll: 'Välj alla',
    unselectAll: 'Avmarkera alla',
    haveBookingThis: 'Du är inbokad!',
    courseFull: 'Fullbokat!',
    waitingList: 'Väntelista',
    waitingLists: 'Väntelistor',
    payments: 'Betalningar',
    myPayments: 'Mina betalningar',
    loadMore: 'Visa fler',
    occasion: 'tillfälle',
    occasions: 'tillfällen',
    waitingListText: 'Du är på väntelistan!',
    leaveWaitingList: 'Lämna väntelista',
    confirmYourOrder: 'Bekräfta din order',
    subscriptionInfoTitle: 'Abonnemangsbetalning',
    subscriptionInfoCardDescription1:
      'Betalningen skapar ett abonnemang och nästa betalning görs',
    subscriptionInfoCardDescription2: ' {{time}}.',
    subscriptionInfoCardDescription3FirstPayment:
      ' Denna första betalning har ett reducerat pris då den inte motsvarar en full period, kommande betalningar görs med fullt pris.',
    subscriptionConfirmCardDescription1: 'Ditt kort kommer att debiteras',
    subscriptionConfirmCardDescription2: ' {{time}} ',
    subscriptionConfirmCardDescription3: 'till och med avbokning.',
    subscriptionInfoInvoiceDescription1:
      'Betalningen skapar ett abonnemang och nästa faktura skickas',
    subscriptionInfoInvoiceDescription2: ' {{time}}.',
    subscriptionInfoInvoiceDescription3FirstPayment:
      ' Denna första faktura har ett reducerat pris då den inte motsvarar en full period, kommande fakturor är med fullt pris.',
    subscriptionConfirmInvoiceDescription1: 'Fakturering sker',
    subscriptionConfirmInvoiceDescription2: ' {{time}} ',
    subscriptionConfirmInvoiceDescription3: 'till och med avbokning.',
    myBookings: 'Mina bokningar',
    myVouchers: 'Mina värdekort',
    myWaitingLists: 'Mina väntelistor',
    myMemberships: 'Mina medlemskap',
    onWaitingList: 'Anmäld',
    cancelBooking: 'Avboka',
    orderDetailsTitle: 'Orderdetaljer',
    yearly: 'årsvis',
    quarterly: 'kvartalsvis',
    monthly: 'månadsvis',
    weekly: 'veckovis',
    daily: 'dagligen',
    showMore: 'Visa mer',
    confirm: 'Bekräfta',
    checkoutBankIDCardTitle: 'BankID',
    checkoutBankIDStartAuth: 'Identifiera med BankID',
    checkoutBankIDIdentify: 'Identifiera dig med BankID',
    checkoutBankIDOpenOnThisDevice: 'Öppna BankID på den här enheten istället',
    checkoutBankIDOpenOnDifferentDevice: 'Öppna BankID på en annan enhet istället',
    checkoutBankIDOpenBankID: 'Öppna BankID',
    checkoutBankIDTryAgain: 'Försök igen',
    checkoutBankIDCancel: 'Avbryt',
    checkoutBankIDCompleted: 'Verifierad med BankID!',
    checkoutBankIDNotCompleted: 'BankID är inte slutfört',
    checkoutBankIDHintCodeOutstandingTransactionDesktop:
      'Starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan.',
    checkoutBankIDHintCodeOutstandingTransactionMobile:
      'Klicka på knappen nedan för att starta BankID-appen.',
    checkoutBankIDHintCodeUserSign:
      'Skriv din säkerhetskod i BankID- appen och välj Identifiera eller Skriv under.',
    checkoutBankIDHintCodeUserCancel: 'Åtgärden avbröts.',
    checkoutBankIDHintCodeStartFailed:
      'Skanningen misslyckades. Starta BankID-appen och skanna QR koden. Kontrollera att appen är uppdaterad. Om du inte har appen måste du installera den och skaffa ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
    checkoutSignDocumentTitle: 'Skriv under avtal',
    checkoutSignDocumentNotSentPart1Text:
      'För att slutföra bokningen behöver du skriva under ',
    checkoutSignDocumentNotSentPart2Text: 'ett avtal. ',
    checkoutSignDocumentNotSentPart3Text:
      'Klicka på knappen nedan för att få det skickat till dig via ',
    checkoutSignDocumentNotSentPart4Text: 'email och/eller sms.',
    checkoutSignDocumentNotSentPart3TextApiDelivery:
      'Klicka på knappen nedan för att öppna avtalet och ',
    checkoutSignDocumentNotSentPart4TextApiDelivery: 'signera.',
    checkoutSignDocumentPendingPart1Text: 'Väntar på att avtalet blir signerat...',
    checkoutSignDocumentPendingPart2Text:
      'Klicka på den gröna knappen nedan när du har signerat avtalet.',
    checkoutSignDocumentReturnToFinishText:
      'Återvänd hit efter att du har signerat avtalet och fortsätt med stegen nedan för att slutföra bokningen.',
    checkoutSignDocumentSignedText: 'Avtalet är signerat!',
    checkoutSignDocumentSendButton: 'Skicka avtal',
    checkoutSignDocumentOpenButton: 'Öppna avtalet',
    checkoutSignDocumentPendingButton: 'Jag har signerat!',
    checkoutSignDocumentNotSignedError:
      'Tyvärr så har avtalet inte blivit signerat ännu, var vänlig försök igen.',
    checkoutAddonsMandatoryError: 'Välj minst ett tillägg',
    checkoutItemUserProxyTitle: 'Bokar du åt en anhörig?',
    checkoutItemUserProxyDescription: 'Lägg till personen du vill göra bokningen åt',
    checkoutItemUserProxyMaxRecipientsDescription:
      'Lägg till personen du vill göra bokningen åt, max {{maxRecipients}} kan bokas.',
    checkoutItemAddUserProxyButton: 'Lägg till person',
    checkoutItemAddUserProxyToBookingButton: 'Välj',
    checkoutItemDeleteUserProxyTitle: 'Ta bort från bokning',
    checkoutItemDeleteUserProxySubtitle: 'Vill du ta bort personen från bokningen?',
    checkoutItemDeleteUserProxyYes: 'Ja, ta bort',
    checkoutItemUserProxyMandatoryError: 'Välj minst en anhörig',
    checkoutItemUserProxyAlreadyBookedError: 'Personen är redan bokad',
    checkoutItemUserProxyBookingFullError:
      'Personen kan inte läggas till då bokningen är fullbokad',
    checkoutItemUserProxyMaxRecipientsError: 'Max antal personer har lagts till',
    addUserProxyModalTitle: 'Lägg till person',
    addUserProxyModalSubtitle: 'Fyll i information för den personen som bokningen gäller',
    addUserProxyModalSectionName: 'Namn',
    addUserProxyModalSectionBirthday: 'Födelsedag',
    addUserProxyModalSectionEmail: 'E-mail',
    addUserProxyErrorEmailExists: 'E-mailen finns redan registrerad',
    birthday: 'Födelsedag',
    deleteAccountButton: 'Radera konto',
    deleteAccountModalTitle: 'Radera ditt konto',
    deleteAccountModalText:
      'Är du säker på att du vill radera ditt konto? Kontot kan inte att återställas i efterhand.',
    deleteAccountModalButtonConfirm: 'Ja, radera',
    deleteAccountModalButtonCancel: 'Avbryt',
    errorAccountDeleteActiveBookings:
      'Kontot har aktiva bokningar och kan därför inte raderas. Var vänlig hantera bokningarna först och försök igen',
    errorEmailLogin: 'Emailen och/eller lösenordet är felaktigt',
    errorEmailRegistration: 'Det finns redan ett konto med denna email',
    errorRequired: 'Obligatoriskt fält',
    errorInvalidDate: 'Felaktigt datum',
    errorInvalidEmail: 'Ogiltig e-mail',
    errorPasswordShort: 'Lösenordet är för kort',
    errorConfirm: 'Lösenorden måsta matcha',
    errorCountry: 'Land är obligatoriskt',
    errorFirstName: 'Förnamn är obligatoriskt',
    errorLastName: 'Efternamn är obligatoriskt',
    errorPhoneNumber: 'Telefonnummer är obligatoriskt',
    errorEmailField: 'Email är obligatoriskt',
    errorPostal: 'Postkod är obligatoriskt',
    errorStreet: 'Adress är obligatoriskt',
    errorCity: 'Stad är obligatoriskt',
    errorCompany: 'Företag är obligatoriskt',
    errorCompanyNumber: 'Organisationsnummer är obligatoriskt',
    errorCompanyNumberField: 'Ogiltigt format',
    errorIdentityNumber: 'Personnummer är obligatoriskt',
    errorMessage: 'Meddelandet är obligatoriskt',
    errorCheckoutConfirmed: 'Den här kassan har redan slutförts',
    errorCheckoutNotFound: 'Kassan kunde inte hittas',
    errorHashNotFound: 'Betalningslänken är ogiltig',
    errorLinkExpired: 'Betalningslänken är inte längre giltig',
    errorCheckoutDeleted: 'Bokningen har blivit borttagen',
    errorMessageDefault: 'Något gick fel, var vänlig försök igen',
    waitingListModalTitle: 'Lägg till dig själv eller någon annan på väntelistan?',
    waitingListModalAddYourself: 'Mig själv',
    waitingListModalAddSomeoneElse: 'Någon annan',
    waitingListModalAddNames: 'Lägg till personer på väntelistan',
    waitingListModalChooseNames: 'Välj personer eller skapa en ny',
    waitingListModalAddPerson: 'Lägg till ny person',
    makeItAGift: 'Ge bort som present',
    recipientEmail: 'Mottagarens email',
    giftFirstName: 'Mottagarens förnamn',
    giftLastName: 'Mottagarens efternamn',
    errorGiftEmail: 'Email är obligatoriskt',
    errorGiftFirstName: 'Förnamn är obligatoriskt',
    errorGiftLastName: 'Efternamn är obligatoriskt',
    giftRecipientAdd: '+ Lägg till mottagare',
    giftRecipientRemove: '- Ta bort mottagare',
    giftRequired: 'Lägg till mottagare för presenten',
    scheduleDescription: 'Veckans grupptillfällen',
    hide: 'Göm',
    show: 'Visa',
    noOccasions: 'Hittade inga tillgängliga grupptillfällen',
  },
};
