import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { callApi, deleteApi } from 'helpers';
import { ServiceType } from './ServiceListStore';

type PaymentType = {
  transaction: {
    amount: number;
    createdAt: string;
    currency: string;
    id: number;
    paidAt: string;
    paymentType: string;
    refundedAmount: number;
    serialNumber: string;
    status: string;
  };
};

type Profile = {
  description: string;
  email: string;
  id: number;
  logoUrl: string;
  phone: string;
  website: string;
  name: string;
  address?: string;
};

type Resources = [
  {
    avatarUrl: string;
    description: string;
    id: number;
    name: string;
    type: string;
  }
];

export type Entries = {
  endTime: string;
  id: number;
  startTime: string;
  type: string;
  resources: Resources | [];
  isCancellable: boolean;
};

export type Booking = {
  createdAt: string;
  currency: string;
  day: string;
  entries: Entries[];
  id: number;
  price: number;
  payment?: PaymentType;
  profile: Profile;
  status: string;
  transactionLinkHash?: string;
  service: ServiceType;
  isCancellable: boolean;
};

export type Pagination = {
  page: number;
  perPage: number;
  totalPages: number;
  totalRows: number;
};

export class Bookings {
  bookings?: Booking[][] = undefined;
  pagination?: Pagination = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setBookings = (bookings: Booking[][] | []) => {
    this.bookings = bookings;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  deleteBooking = async (
    id: number,
    entryId: number | undefined,
    callback: () => void
  ) => {
    const res = await deleteApi(
      `v1/bookings/${id}${entryId ? `?entryId=${entryId}` : ''}`
    );
    const data = await res.json();

    const bookings = toJS(this.bookings);
    if (bookings) {
      for (let i = 0; i < bookings?.length; i++) {
        for (let j = 0; j < bookings[i]?.length; j++) {
          if (bookings[i][j].id === data.id) {
            if (!entryId || !data.entries || data.entries.length === 0) {
              bookings[i].splice(j, 1);
              if (!bookings[i].length) {
                bookings.splice(i, 1);
              }
            } else {
              bookings[i][j] = data;
            }
          }
        }
      }

      runInAction(() => {
        this.bookings = bookings;
      });

      callback();
    }
  };

  getBookings = async (page?: number, perPage?: number): Promise<void | Booking[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/users/me/bookings/entries?page=${page}&perPage=${perPage}`
      );
      const data = await res.json();
      const { data: bookings, ...pagination } = data;
      const sortedBookings: Booking[][] = [];
      if (bookings.length > 0) {
        let bookingsGroup: Booking[] = [];
        let { day } = bookings[0];
        let {
          profile: { id },
        } = bookings[0];
        for (let i = 0; i < bookings.length; i++) {
          if (bookings[i].day === day && id === bookings[i].profile.id) {
            bookingsGroup.push(bookings[i]);
          } else {
            sortedBookings.push(bookingsGroup);
            day = bookings[i].day;
            id = bookings[i].profile.id;
            bookingsGroup = [bookings[i]];
          }
        }
        sortedBookings.push(bookingsGroup);
      }

      this.setBookings([...(this.bookings || []), ...sortedBookings]);
      this.setPagination(pagination);
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };
}

export const BookingStore = new Bookings();
